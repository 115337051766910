<template>
  <div class="container py-5">
    <p class="fw-bold">ICDA 2022</p>
    <p>
    The OBSERV has landed in Thailand. 
    <br>
    Thank you, team Lasermed Co., Ltd. at the ICAD 2022 in Bangkok.
    </p>

    <img
      class="img-md rounded"
      src="@/assets/images/zh-TW/news/new_10.jpg"
    />
  </div>
</template>
<script>
export default {};
</script>
